export default {
  title: {
    window: 'cardiolectra - cms v{version}',
    home: 'Home',
    dataset: 'Dataset',
    datasets: 'Datasets',
    graph: 'Graph',
    users: 'Users',
    run: 'Run',
    node: 'Node',
    strings: 'Texts',
    settings: 'Settings',
    language: 'Language',
    content: 'Content',
    animation: 'Animation'
  },
  btn: {
    save: 'Save',
    autosave: 'Autosave',
    create: 'Create',
    refresh: 'Refresh',
    reset: 'Reset',
    login: 'Login',
    ok: 'Ok',
    back: 'Back',
    ignore: 'Ignore',
    reload: 'Reload',
    recover: 'Recover',
    cancel: 'Cancel',
    selectall: 'Select all',
    deselectall: 'Deselect all'
  },
  error: {
    api: {
      unsupported_frontend_version:
        'A new version is available. Please reload the page.',
      critical_network_error:
        'A critical network error has occured. Please reload the page.'
    }
  },
  login: {
    label: {
      email: 'Email',
      code: 'Code',
      password: 'Password',
      new_password: 'New password',
      repeat_password: 'Repeat password'
    },
    hint: {
      reset: 'A reset link will be emailed to you.',
      recover: 'Use the recovery code that has been emailed to you.'
    },
    link: {
      reset: 'Reset password?'
    }
  },
  navbar: {
    logout: 'Logout',
    login: 'Login'
  },
  home: {
    welcome: 'Welcome'
  },
  tester: {
    maxlevel: 'Max. nested level',
    missing_content: '- missing -'
  },
  dataset: {
    name: 'Name',
    last_saved: 'last saved',
    by_user: 'by user',
    copy_of: 'Copy of {name}',
    confirm_delete: 'Are you sure you want to delete this dataset?'
  },
  tooltip: {
    edit: 'Edit',
    settings: 'Settings',
    clone: 'Clone',
    export: 'Export',
    del: 'Delete'
  },
  placeholder: {
    search_label: 'Search label',
    create_label: 'Create label'
  },
  strings: {
    import: 'Import',
    export: 'Export',
    cleanup: 'Clean up',
    cleanup_count: 'Delete {count} of {total} unused texts?',
    results_more: '{count} additional matches, not shown',
    results_empty: '- no matches -',
    confirm_delete: 'Are you sure you want to delete this text?',
    import_report: {
      title: 'File "{fn}" imported',
      updated: '{cnt} of {total} texts updated',
      created: '{cnt} of {total} texts created'
    },
    error: {
      create_label_missing: 'Missing label.',
      create_label_exists: 'Label already exists.'
    }
  },
  user: {
    active: 'Active',
    login: 'Login',
    created_at: 'Created at',
    updated_at: 'Updated at',
    confirm_delete: 'Are you sure you want to delete this user?',
    title: {
      flags: 'Flags',
      access_rights: 'Access rights'
    },
    rights: {
      manage_users: 'Manage users',
      create_and_delete_datasets: 'Create and delete datasets',
      modify_datasets: 'Modify datasets',
      export_datasets: 'Export datasets',
      create_and_delete_nodes: 'Create and delete nodes',
      modify_nodes: 'Modify nodes',
      create_and_delete_strings: 'Create and delete texts',
      modify_strings: 'Modify texts'
    }
  },
  graph: {
    switches: {
      warnings: 'Warnings',
      errors: 'Errors'
    },
    vis: {
      remove: 'Remove',
      back: 'Cancel',
      add_node: 'Add node',
      add_edge: 'Connect',
      hint_add_node: 'Click in an empty space to place a new node.',
      hint_add_edge:
        'Click on a node and drag the edge to another node to connect them.'
    }
  },
  inspector: {
    empty_content: '- no selection -',
    goto_string: 'Go to text',
    goto_node: 'Go to node',
    speciallabel: {
      node_connections: 'Connections ({count})',
      node_errors: 'Errors ({count})',
      node_warnings: 'Warnings ({count})'
    },
    errors: {
      'label-missing': 'Missing label',
      'caption-missing': 'Missing caption text',
      'headline-missing': 'Missing headline text',
      'caption-translations': 'Missing languages ({arg0}) in caption text',
      'content-translations': 'Missing languages ({arg0}) in content text',
      'headline-translations': 'Missing languages ({arg0}) in headline text'
    },
    keylabel: {
      label: 'Label',
      caption: 'Caption',
      content: 'Content',
      headline: 'Headline',
      anim_id: 'Animation ID',
      sortvalue: 'Menu sort',
      products: 'Products',
      menu_id: 'Menu ID',
      text_gb: 'Text (gb)',
      text_de: 'Text (de)',
      text_es: 'Text (es)',
      text_nl: 'Text (nl)',
      text_fr: 'Text (fr)',
      text_se: 'Text (se)'
    }
  }
}
