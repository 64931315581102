








_ = require 'lodash'

module.exports =
  props:
    name:
      type: String
      default: ''
    value:
      type: Number
      default: 0
    min:
      type: Number
      default: -10
    max:
      type: Number
      default: 10

  data: ->
    editvalue: ''

  watch:
    value: ->
      @editvalue = @value

  mounted: ->
    @editvalue = @value

  methods:
    onChange: ->
      @editvalue = _.toInteger @editvalue
      @editvalue = _.clamp @editvalue, @min, @max
      @$emit 'change',
        key: @name
        value: @editvalue

