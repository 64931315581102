


















































_ = require 'lodash'
Vue = require 'vue'
XLSX = require 'xlsx'
filesaver = require 'file-saver'

{dataset} = require '../../store'
state = require '../../state'
utils = require '../../utils'
conf = require '../../conf'

#-------------------------------------------------------------------------------

XLSX_HEADER = _.concat [ 'id', 'label' ], _.map conf.dataset.strings.locales, (l) -> "text_#{l}"

fixEncoding = (require 'he').decode

#-------------------------------------------------------------------------------

module.exports =
  mixins: [
    require '../../mixins/user'
  ]

  components:
    modal: require '../modal'
    cleanup: require './cleanup'

  data: ->
    focusbox: ''
    filter:
      text: ''
    results:
      total: 0
      count: 0
      strings: []
    new_string_label: ''
    modal_delete:
      open: no
      id: ''
    modal_error:
      open: no
      text: ''
    modal_cleanup:
      open: no
    modal_import:
      open: no
      cnt: {}

  mounted: ->
    dataset.on 'strings.changed', @_onChanged
    dataset.on 'loaded', @_onChanged
    @$refs.import_file.addEventListener 'change', @_onImportFile
    @refresh()

  beforeDestroy: ->
    @$refs.import_file.removeEventListener 'change', @_onImportFile
    dataset.off 'loaded', @_onChanged
    dataset.off 'strings.changed', @_onChanged

  methods:
    _onChanged: ->
      @results = dataset.strings.search @filter

    _onImportFile: (e) ->
      f = _.first e.target.files
      return unless _.endsWith (_.toLower f?.name), '.xlsx'
      reader = new FileReader
      reader.onload = (e) =>
        wb = XLSX.read e.target.result, type: 'binary'
        ws = wb.Sheets[wb.SheetNames[0]]
        ws = XLSX.utils.sheet_to_json ws,
          header: XLSX_HEADER
        ws.shift()
        ws = _.map ws, (s) ->
          return unless s.id and s.label
          s = _.pick s, XLSX_HEADER
          _.each XLSX_HEADER, (k) -> s[k] = fixEncoding s[k] or ''
          s

        ws = _.compact ws
        res = dataset.strings.import ws
        @$refs.import_file.value = ''
        state.selectString null
        @modal_import = _.assign {},
          open: yes
          fn: f.name
          cnt: res
      reader.readAsBinaryString f

    onFocus: (e) ->
      e.target.select()

    refresh: ->
      @results = dataset.strings.search @filter
      #state.selectString null

    doCleanup: ->
      @modal_cleanup.open = yes

    doExport: ->
      rows = dataset.strings.toRows XLSX_HEADER
      rows.unshift XLSX_HEADER
      blob = utils.writeXLSX rows, 'Texts' # FIXME i18n
      filesaver.saveAs blob, utils.exportFilename
        main: dataset.getName()
        suffix: 'Texts' # FIXME i18n
        ext: 'xlsx'

    createString: ->
      dataset.strings.create label: @new_string_label, (err, str) =>
        if err
          @modal_error.open = yes
          @modal_error.text = @$t 'strings.error.'+err
        else
          @new_string_label = ''
          @filter.text = str.label
          state.selectString str
          @results = dataset.strings.search text: str.label

    cloneString: (s) ->
      # TODO: this has been broken for a long time and nobody noticed
      return unless @user.cms_rights.create_and_delete_strings
      l = "#Copy of '#{s.label}'"
      ns = _.assign (_.omit s, 'id'), label: l
      dataset.strings.create ns, (err, str) =>
        if err
          @modal_error.open = yes
          @modal_error.text = @$t 'strings.error.'+err
        else
          @filter.text = str.label
          @refresh()

    deleteString: (s) ->
      return unless @user.cms_rights.create_and_delete_strings
      @modal_delete = _.assign {},
        id: s.id
        open: yes

    onCloseModalDelete: (res) ->
      @modal_delete.open = no
      return unless res is 'ok'
      dataset.strings.delete id: @modal_delete.id

    onCloseModalCleanup: (res) ->
      @modal_cleanup.open = false
      @refresh()

    selectString: (s) ->
      state.selectString s

    highlight: (l) -> @filter$highlight l, @filter.text

