const EventEmitter = require('eventemitter2').EventEmitter2

const { dataset } = require('../store')

//-------------------------------------------------------------------------------

class State extends EventEmitter {
  constructor() {
    super()
    this.tester = null
    this.graph = null
    this._selected_string = null
    this._selected_node = null

    dataset.on('loaded', () => {
      this.tester = null
      this.graph = null
      if (
        !this._selected_string ||
        !dataset.strings.get(this._selected_string)
      ) {
        this._selected_string = null
      }
      if (!this._selected_node || !dataset.graph.getNode(this._selected_node)) {
        return (this._selected_node = null)
      }
    })

    dataset.on('strings.changed', () => {
      if (
        !this._selected_string ||
        !dataset.strings.get(this._selected_string)
      ) {
        return (this._selected_string = null)
      }
    })

    dataset.on('graph.changed', () => {
      if (!this._selected_node || !dataset.graph.getNode(this._selected_node)) {
        return (this._selected_node = null)
      }
    })
  }

  selectString(s) {
    s = s && dataset.strings.get(s)
    const changed =
      (this._selected_string != null ? this._selected_string.id : undefined) !==
      (s != null ? s.id : undefined)
    if (changed) {
      this._selected_string = s
    }
    if (changed) {
      return this.emit('string.selected', s)
    }
  }

  getSelectedString() {
    return this._selected_string
  }

  selectNode(n, opt) {
    if (!opt) {
      opt = {}
    }
    n = n && dataset.graph.getNode(n)
    const changed =
      (this._selected_node != null ? this._selected_node.id : undefined) !==
      (n != null ? n.id : undefined)
    if (changed) {
      this._selected_node = n
    }
    if (changed) {
      return this.emit('node.selected', n, opt)
    }
  }

  getSelectedNode() {
    return this._selected_node
  }
}

//-------------------------------------------------------------------------------

module.exports = new State()
