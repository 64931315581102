






































_ = require 'lodash'
Vue = require 'vue'

{dataset} = require '../../store'
conf = require '../../conf'
state = require '../../state'

#-------------------------------------------------------------------------------

connectGraph = (graph) ->
  connect = (n) ->
    return unless n
    return n if n._children
    n._children = []
    _.each graph.edges, (e) ->
      n._children.push (_.find graph.nodes, id: e.to) if e.from is n.id
    _.each n._children, (nc) -> connect nc
    return n
  graph.root = connect (_.find graph.nodes, id: 'root')
  graph

#-------------------------------------------------------------------------------

module.exports =
  components:
    testerMenu: require './menu'

  mounted: ->
    dataset.on 'loaded', @rebuild
    dataset.on 'changed', @rebuild
    @rebuild()

  beforeDestroy: ->
    dataset.off 'changed', @rebuild
    dataset.off 'loaded', @rebuild

  data: ->
    graph: null
    root: null
    secondary: null
    current: null
    maxlevel: state.tester?.maxlevel or 3
    locale: state.tester?.locale or conf.dataset.strings.default_locale
    locales: conf.dataset.strings.locales

  watch:
    maxlevel: (v) -> _.set state, 'tester.maxlevel', v
    locale: (v) -> _.set state, 'tester.locale', v

  computed:
    content: ->
      s = @current and dataset.strings.translate @current.content, @locale
      s or= ''
      s = @filter$highlight s, 'locale:'
      s or= '&nbsp;'

    content_label: ->
      s = @current and dataset.strings.get @current.content
      s = s?.label or s?.id
      s or= @current?.content

  methods:
    rebuild: ->
      @graph = connectGraph JSON.parse dataset.graph._serialize()
      s = _.get state, 'tester.global', {}
      @root = (_.find @graph.nodes, id:s.primary) or @graph.root
      @secondary = (_.find @graph.nodes, id:s.secondary) or null
      @current = (_.find @graph.nodes, id:s.current) or null

    onOpen: (b) ->
      @secondary = b if b?.menu_id
      @current = b
      _.set state, 'tester.global',
        primary: @root?.id
        secondary: @secondary?.id
        current: @current?.id

    caption: (n) ->
      n and dataset.strings.translate n.caption, @locale, "label:#{n.label or n.id}"

    contentError: (c) -> _.startsWith c, '<strong>'

    gotoString: (s) ->
      state.emit 'goto.string', s

    gotoNode: (n) ->
      state.emit 'goto.node', n.id

