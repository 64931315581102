const moment = require('moment')
const sanitizeFn = require('sanitize-filename')

//-------------------------------------------------------------------------------

module.exports = {
  writeXLSX: require('./writexlsx'),

  sanitizeSearchString(s) {
    return (s || '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '')
  },

  exportFilename(opt) {
    let date = moment(opt.date || undefined)
    date = date.format('YYYYMMDD_HHmmss')
    const main = opt.main || '#'
    const sx = (opt.suffix && `.${opt.suffix}`) || ''
    const ext = (opt.ext && `.${opt.ext}`) || ''
    return sanitizeFn(`${date}-${main}${sx}${ext}`)
  }
}
