














_ = require 'lodash'
Vue = require 'vue'

module.exports =
  props:
    value:
      type: String
      default: ''
    placeholder:
      type: String
      default: ''
    textKey:
      type: String
      default: 'label'
    query:
      type: Function
    get:
      type: Function

  data: ->
    querytext: ''
    targetid: ''
    suggestions: []
    selection: -1
    missing: no
    pristine: yes

  watch:
    value: (v) -> @setid v

  mounted: ->
    @setid @value
    window.document.addEventListener 'click', @blur

  beforeDestroy: ->
    window.document.removeEventListener 'click', @blur

  methods:
    setid: (id) ->
      if id
        s = id and @get? id
        @targetid = s?.id or id
        @querytext = if s then (s[@textKey] or '') else "missing:#{id}"
        @missing = not s
      else
        @targetid = @querytext = ''
        @missing = no
      @pristine = yes
      @$nextTick -> @reset()

    search: -> @suggestions = @query? @querytext

    highlight: (txt) -> @filter$highlight txt, @querytext

    select: (idx) ->
      s = @suggestions[idx]
      return if not s and @targetid and _.isEmpty @suggestions
      @setid s?.id or ''
      @$emit 'change', @targetid

    up: ->
      @selection = Math.max 0, @selection - 1
      @$emit 'choice', @suggestions[@selection]

    down: ->
      @selection = Math.min @suggestions.length-1, @selection + 1
      @$emit 'choice', @suggestions[@selection]

    keyup: (e) ->
      special = _.includes [ 38, 40, 13 ], e.which
      @pristine = no unless special
      @search() unless special
      @select @selection if e.which is 13

    reset: ->
      @suggestions = []
      @selection = -1

    blur: ->
      @setid @targetid

