



















api = require '../services/api'

module.exports =
  components:
    modal: require './modal'

  data: ->
    reload_error: ''
    extra_info: ''
    can_ignore: no

  mounted: ->
    api.on 'request.error', @_requestError

  beforeDestroy: ->
    api.off 'request.error', @_requestError

  methods:
    _requestError: (req, err) ->
      switch req.path
        when '/login' then return

      status = err.response?.status or err
      statustext = err.response?.statusText or ''
      error = err.response?.data?.error

      unless @reload_error
        if error is 'unsupported-frontend-version'
          @reload_error = @$t 'error.api.unsupported_frontend_version'
          @extra_info = ''
          @can_ignore = no
        else if status isnt 500 and status isnt 401
          @reload_error = @$t 'error.api.critical_network_error'
          @extra_info = error or "#{status} #{statustext}"
          @can_ignore = yes

    reload: ->
      window.location.reload()

    ignore: ->
      @reload_error = ''

