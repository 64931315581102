if (!process.env.PACKAGE_VERSION) {
  throw new Error('PACKAGE_VERSION not set')
}

if (typeof console !== 'undefined' && console !== null) {
  console.info(`${process.env.NODE_ENV}, v${process.env.PACKAGE_VERSION}`)
}

const PRODUCTION = process.env.NODE_ENV !== 'production'

/*
if (module.hot && !PRODUCTION) {
  module.hot.accept()
}
*/

if (process.env.NODE_ENV === 'testing') {
  document.body.innerHTML =
    '<div style="background-color: rgba(255, 0, 0, 0.62); position: fixed; left: 0; right: 0; top: 0; z-index: 99999; text-align: center; color: #fff; font-size: 14px; font-style: bold; line-height: 1;">TESTING ENVIRONMENT</div>' +
    document.body.innerHTML
}

//-----------------------------------------------------------------------------

import Vue from 'vue'
Vue.config.debug = !PRODUCTION
Vue.config.devtools = false
Vue.config.productionTip = false
