














_ = require 'lodash'
autosize = require 'autosize'

module.exports =
  props:
    name:
      type: String
      default: ''
    value:
      type: String
      default: ''
    multiline:
      type: Boolean
      default: false

  data: ->
    editvalue: ''

  watch:
    value: ->
      @editvalue = @value

  mounted: ->
    @editvalue = @value
    if @multiline
      @$nextTick -> autosize @$el.querySelector 'textarea'

  methods:
    onChange: ->
      @editvalue = _.trim @editvalue unless @multiline
      @$emit 'change',
        key: @name
        value: @editvalue

