//-----------------------------------------------------------------------------

require('./index.sass')

//-----------------------------------------------------------------------------

require('../common/bootstrap')

//-----------------------------------------------------------------------------

import Vue from 'vue'

const _ = require('lodash')
const moment = require('moment')

//-------------------------------------------------------------------------------

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import enUS from './locales/en'

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: enUS
  }
})

//-------------------------------------------------------------------------------

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//-------------------------------------------------------------------------------

const api = require('./services/api')
const utils = require('./utils')

const checkAuth = (required) =>
  function (to, _from, next) {
    if (api.isAuthenticated()) {
      return next()
    }
    if (to.path === '/login') {
      return next()
    }
    return api.refresh(function (err) {
      if (!required || !err) {
        return next()
      }
      return next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    })
  }

//-------------------------------------------------------------------------------

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  linkActiveClass: 'active',
  routes: [
    {
      path: '/login',
      component: require('./views/login'),
      beforeEnter: checkAuth(false)
    },
    {
      path: '/',
      component: require('./views/home'),
      beforeEnter: checkAuth(true)
    },
    {
      name: 'dataset',
      path: '/dataset/:id',
      component: require('./views/dataset'),
      beforeEnter: checkAuth(true)
    },
    {
      path: '/datasets',
      component: require('./views/datasets'),
      beforeEnter: checkAuth(true)
    }
  ]
})

//-------------------------------------------------------------------------------

Vue.filter('truncate', (v, len) => {
  return _.truncate(v, { length: len })
})

Vue.filter('moment', (v, fmt) => {
  return moment(v).format(fmt || 'YYYY.MM.DD HH:mm:ss')
})

Vue.prototype.filter$highlight = (v, phrase) => {
  if (!phrase) {
    return v
  }
  phrase = utils.sanitizeSearchString(phrase)
  const s = v.search(new RegExp(phrase, 'gi'))
  if (!(s >= 0)) {
    return v
  }
  return `${v.substring(0, s)}<strong>${v.substring(
    s,
    s + phrase.length
  )}</strong>${v.substring(s + phrase.length)}`
}

Vue.filter('highlight', Vue.prototype.filter$highlight)

//-------------------------------------------------------------------------------

import App from './app'

new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
