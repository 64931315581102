const XLSX = require('xlsx')

class Workbook {
  constructor() {
    this.SheetNames = []
    this.Sheets = {}
  }
}

const datenum = function (v, date1904) {
  if (date1904) {
    v += 1462
  }
  const epoch = Date.parse(v)
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000)
}

const sheet_from_array_of_arrays = function (data /*, opts*/) {
  const ws = {}
  const range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  }
  let R = 0
  while (R !== data.length) {
    let C = 0
    while (C !== data[R].length) {
      if (range.s.r > R) {
        range.s.r = R
      }
      if (range.s.c > C) {
        range.s.c = C
      }
      if (range.e.r < R) {
        range.e.r = R
      }
      if (range.e.c < C) {
        range.e.c = C
      }
      const cell = { v: data[R][C] }
      if (cell.v === null) {
        ++C
        continue
      }
      const cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R
      })
      if (typeof cell.v === 'number') {
        cell.t = 'n'
      } else if (typeof cell.v === 'boolean') {
        cell.t = 'b'
      } else if (cell.v instanceof Date) {
        cell.t = 'n'
        cell.z = XLSX.SSF._table[14]
        cell.v = datenum(cell.v)
      } else {
        cell.t = 's'
      }
      ws[cell_ref] = cell
      ++C
    }
    ++R
  }
  if (range.s.c < 10000000) {
    ws['!ref'] = XLSX.utils.encode_range(range)
  }
  return ws
}

//-------------------------------------------------------------------------------

module.exports = function (rows, sheetname) {
  const wb = new Workbook()
  const ws = sheet_from_array_of_arrays(rows)
  wb.SheetNames.push(sheetname)
  wb.Sheets[sheetname] = ws
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  })
  const s2ab = function (s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    let i = 0
    while (i !== s.length) {
      view[i] = s.charCodeAt(i) & 0xff
      ++i
    }
    return buf
  }
  return new Blob([s2ab(wbout)], { type: '' })
}
