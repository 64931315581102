const _ = require('lodash')
const EventEmitter = require('eventemitter2').EventEmitter2
const axios = require('axios')

//-------------------------------------------------------------------------------

const TOKEN_STORAGE_KEY = '_token'

if (!process.env.API_BASEURL) {
  throw new Error('API_BASEURL not set')
}

//-------------------------------------------------------------------------------

class API extends EventEmitter {
  constructor() {
    super({ maxListeners: 32 })
    this.user = null
    this.conn = axios.create({
      baseURL: process.env.API_BASEURL
    })
    this._setToken(localStorage.getItem(TOKEN_STORAGE_KEY))
  }

  _setToken(tok) {
    localStorage.setItem(TOKEN_STORAGE_KEY, tok || '')
    this.conn.defaults.headers.authorization = tok && `Bearer ${tok}`
  }

  _reset() {
    this.user = null
    this._setToken(null)
    return this.emit('authorization.change', _.clone(this.user))
  }

  refresh(cb) {
    cb = cb || _.nop
    if (!this.conn.defaults.headers.authorization) {
      return cb('no-token')
    }
    return this.get('/refresh', (err, res) => {
      if (err) {
        return cb(err)
      }
      console.info('api:refresh')
      this.user = res
      if (this.user) {
        this.user.cms_rights = this.user.cms_rights || {}
      }
      //this._setToken(res.token)
      this.emit('authorization.change', _.clone(this.user))
      return cb()
    })
  }

  login(username, password, cb) {
    return this.post(
      { path: '/login', data: { username, password } },
      (err, res) => {
        if (!err) {
          console.info('api:login', res.user.email)
          this.user = res.user
          if (this.user) {
            this.user.cms_rights = this.user.cms_rights || {}
          }
          this._setToken(res.token)
          this.emit('authorization.change', _.clone(this.user))
        }
        return typeof cb === 'function' ? cb(err) : undefined
      }
    )
  }

  logout(cb) {
    cb = cb || _.nop
    if (!this.isAuthenticated()) {
      return cb()
    }
    if (this.user) {
      console.info('api:logout', this.user.email)
    }
    this._reset()
    cb()
  }

  request(opt, cb) {
    const cfg = {
      method: opt.method,
      url: opt.path,
      data: opt.data
    }
    this.emit('request.start', opt)
    return this.conn
      .request(cfg)
      .then((res) => {
        if (typeof cb === 'function') {
          cb(null, res.data)
        }
        return this.emit('request.end', opt, res)
      })
      .catch((err) => {
        console.error(
          `api:${
            (err.response != null ? err.response.status : undefined) || ''
          }`,
          (err.response != null ? err.response.data.error : undefined) || err
        )
        if (typeof cb === 'function') {
          cb(
            (err.response != null ? err.response.data : undefined) ||
              err ||
              'internal-error'
          )
        }
        return this.emit('request.error', opt, err)
      })
  }

  get(opt, cb) {
    if (_.isString(opt)) {
      opt = { path: opt }
    }
    opt.method = 'get'
    return this.request(opt, cb)
  }

  post(opt, cb) {
    if (_.isString(opt)) {
      opt = { path: opt }
    }
    opt.method = 'post'
    return this.request(opt, cb)
  }

  isAuthenticated() {
    return this.user != null
  }

  getUser() {
    return this.user && _.clone(this.user)
  }
}

//-------------------------------------------------------------------------------

module.exports = new API()
