




















































_ = require 'lodash'
api = require '../services/api'

module.exports =
  components:
    modal: require '../components/modal'

  mounted: ->
    if @$route.query.recover
      @mode = 'recover'
      @recover.code = @$route.query.recover
      @recover.email = @$route.query.login or ''
    @working = true
    api.logout =>
      @working = false
      @$refs.loginemail.focus()

  methods:
    setMode: (m) ->
      @mode = m
      @error = ''

    doLogin: ->
      @login.email = _.trim @login.email
      @login.password = _.trim @login.password
      return unless @login.email and @login.password

      @working = true
      api.login @login.email, @login.password, (err) =>
        switch err
          when 'unsupported-frontend-version'
            @error = @$t 'error.api.unsupported_frontend_version'
          else
            @error = err
        @working = false
        @login.password = '' if err
        @$router.replace (@$route.query.redirect or '/') unless err

    doReset: ->
      @reset.email = _.trim @reset.email
      return unless @reset.email

      @working = true
      api.reset @reset.email, (err) =>
        switch err
          when 'unsupported-frontend-version'
            @error = @$t 'error.api.unsupported_frontend_version'
          else
            @error = err
        @working = false
        @setMode 'recover' unless err

    doRecover: ->
      @recover.email = _.trim @recover.email
      @recover.code = _.trim @recover.code
      @recover.password = _.trim @recover.password
      @recover.password2 = _.trim @recover.password2
      return unless @recover.email and @recover.code and @recover.password and @recover.password is @recover.password2

      @working = true
      api.recover @recover.email, @recover.password, @recover.code, (err) =>
        switch err
          when 'unsupported-frontend-version'
            @error = @$t 'error.api.unsupported_frontend_version'
          else
            @error = err
        @working = false
        @recover.password = @recover.password2 = '' if err
        unless err
          @setMode ''
          @$router.replace '/login'

  computed:
    lockedLogin: -> @working or not @login.email or not @login.password
    lockedRecover: ->
      @working or not @recover.email or not @recover.code or not @recover.password or not @recover.password2
    lockedReset: -> @working or not @reset.email

  data: ->
    mode: ''
    login:
      email: ''
      password: ''
    reset:
      email: ''
    recover:
      email: ''
      code: ''
      password: ''
      password2: ''
    error: false
    working: false

