<template>
  <div class="app">
    <spinner></spinner>
    <error-handler></error-handler>
    <navbar ref="navbar" @layout="onNavbarLayout"></navbar>
    <transition name="viewfade" mode="out-in" class="view-transition">
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>

<script>
  const VERSION = process.env.PACKAGE_VERSION

  const removeBootSpinner = () => {
    const e = window.document.getElementById('boot-spinner')
    if (e) {
      e.remove()
    }
  }

  export default {
    metaInfo: {
      meta: [{ name: 'version', content: process.env.PACKAGE_VERSION }]
    },
    components: {
      navbar: require('./components/navbar'),
      spinner: require('./components/spinner'),
      errorHandler: require('./components/error-handler')
    },

    mounted() {
      document.title = this.$t('title.window', { version: VERSION })
      return removeBootSpinner()
    },

    methods: {
      onNavbarLayout(h) {
        return (this.$el.style['padding-top'] = h + 'px')
      }
    },

    template: `\
  `
  }
</script>
