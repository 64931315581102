

















module.exports =
  mixins: [
    require '../mixins/user'
  ]

  mounted: ->
    prevh = 0
    check = =>
      h = @$el.offsetHeight
      return unless h and h isnt prevh
      prevh = h
      @$emit 'layout', h
    @_interval = setInterval check, 1000

  beforeDestroy: ->
    clearInterval @_interval

  data: ->
    _interval: null

