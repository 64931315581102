














































_ = require 'lodash'
filesaver = require 'file-saver'

utils = require '../utils'
api = require '../services/api'

module.exports =
  mixins: [
    require '../mixins/user'
  ]

  components:
    modal: require '../components/modal'

  mounted: ->
    @refresh()

  data: ->
    datasets: []
    modal_settings:
      id: ''
      open: no
      name: ''
    modal_delete:
      id: ''
      open: no

  methods:
    refresh: ->
      @datasets = []
      api.get '/datasets', (err, res) =>
        @datasets = res or []

    create: ->
      @datasets = []
      api.get '/dataset/new', => @refresh()

    del: (ds) ->
      @modal_delete = _.assign {},
        id: ds._id
        open: yes

    settings: (ds) ->
      @modal_settings = _.assign {},
        id: ds._id
        open: yes
        name: ds.name

    download: (ds) ->
      api.get path: "/dataset/#{ds._id}", block: yes, (err, ds) ->
        return console.error err if err # TODO modal
        ds.graph = JSON.parse ds.graph or '{}'
        ds.strings = JSON.parse ds.strings or '[]'
        blob = new Blob [ JSON.stringify ds ], type: 'text/json;charset=utf8;'
        filesaver.saveAs blob, utils.exportFilename
          date: ds.last_saved
          main: ds.name
          ext: 'json'

    clone: (ds) ->
      clone_keys = [ 'name', 'graph', 'strings' ]
      api.get path: "/dataset/#{ds._id}", block: yes, (err, orig) =>
        return console.error err if err # TODO modal
        api.get path: "/dataset/new", block: yes, (err, clone) =>
          return console.error err if err # TODO modal
          orig = _.pick orig, clone_keys
          clone = _.assign {}, clone, orig,
            name: @$t 'dataset.copy_of', name: orig.name
          api.post path: "/dataset/#{clone._id}/save", data: clone, block: yes, (err, res) =>
            return console.error err if err # TODO modal
            @refresh()

    onCloseModalSettings: (res) ->
      @modal_settings.open = no
      return unless res is 'ok'
      return unless @modal_settings.name
      ds =
        _id: @modal_settings.id
        name: @modal_settings.name
      api.post path: "/dataset/#{ds._id}/save", data: ds, block: yes, (err, res) =>
        return console.error err if err # TODO modal
        @refresh()

    onCloseModalDelete: (res) ->
      @modal_delete.open = no
      return unless res is 'ok'
      @datasets = []
      api.get "/dataset/#{@modal_delete.id}/delete", (err, res) => @refresh()

