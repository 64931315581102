const _ = require('lodash')
import { v4 as uuid } from 'uuid'

const conf = require('../../conf')
const utils = require('../../utils')

//-------------------------------------------------------------------------------

class Strings {
  constructor(_dataset) {
    this._dataset = _dataset
  }

  _reset() {
    return (this._strings = [])
  }

  _serialize() {
    return JSON.stringify(this._strings)
  }

  _deserialize(data) {
    return (this._strings = JSON.parse(data || '[]'))
  }

  _changed() {
    this._dataset.emit('strings.changed')
    return this._dataset._changed()
  }

  get(data) {
    const id = (data != null ? data.id : undefined) || data
    if (id) {
      return _.find(this._strings, { id })
    }
  }

  validate(data) {
    const s = this.get(data)
    if (!s) {
      return 'MISSING'
    }
    const res = _.compact(
      _.map(conf.dataset.strings.locales, function (l) {
        if (s[`text_${l}`]) {
          return false
        } else {
          return l
        }
      })
    )
    if (_.isEmpty(res)) {
      return
    }
    return res
  }

  translate(data, locale, alt) {
    if (locale == null) {
      locale = conf.dataset.strings.default_locale
    }
    const res = this.get(data)
    if (!res) {
      return alt
    }
    const txt = res[`text_${locale}`] || ''
    if (!txt) {
      return `locale:${res.label}`
    }
    return txt
  }

  query(q) {
    q = utils.sanitizeSearchString(q)
    q = new RegExp(q, 'gi')
    let res = _.filter(
      this._strings,
      (s) => (s.label != null ? s.label.search(q) : undefined) >= 0
    )
    return (res = _.map(res, (s) => _.pick(s, ['id', 'label'])))
  }

  search(filter, locale) {
    if (locale == null) {
      locale = conf.dataset.strings.default_locale
    }
    const max = 50
    let total = 0
    let res = []
    const txt = utils.sanitizeSearchString(filter.text)
    const ft = txt && new RegExp(txt, 'gi')
    for (let s of Array.from(this._strings)) {
      if (ft && s.label.search(ft) < 0) {
        continue
      }
      if (total < max) {
        res.push(s)
      }
      total += 1
    }
    res = _.map(res, (s) => ({
      id: s.id,
      label: s.label,
      text: s[`text_${locale}`] || ''
    }))
    return (res = {
      total,
      count: _.size(res),
      strings: res
    })
  }

  create(data, done) {
    if (!(data != null ? data.label : undefined)) {
      return done('create_label_missing')
    }
    if (_.findIndex(this._strings, { label: data.label }) >= 0) {
      return done('create_label_exists')
    }
    const str = _.assign({}, data, { id: uuid() })
    this._strings.push(str)
    this._changed()
    return done(null, str)
  }

  delete(data) {
    data = _.castArray(data)
    let cnt = 0
    _.each(data, (s) => {
      const idx = _.findIndex(this._strings, { id: s.id })
      if (!(idx >= 0)) {
        return
      }
      this._strings.splice(idx, 1)
      return (cnt += 1)
    })
    if (cnt) {
      this._changed()
    }
    return cnt
  }

  change(data) {
    const s = _.find(this._strings, { id: data.id })
    if (!s) {
      return
    }
    _.merge(s, _.omit(data, 'id'))
    return this._changed()
  }

  toRows(keys) {
    return _.map(this._strings, (s) => _.map(keys, (k) => s[k] || ''))
  }

  import(data) {
    const k = _.concat(
      ['id', 'label'],
      _.map(conf.dataset.strings.locales, (l) => `text_${l}`)
    )
    data = _.castArray(data)
    const cnt = {
      total: 0,
      updated: 0,
      created: 0
    }
    _.each(data, (s) => {
      cnt.total += 1
      const i = _.findIndex(this._strings, { id: s.id })
      if (i >= 0) {
        cnt.updated += 1
        return _.assign(this._strings[i], _.pick(s, k))
      } else {
        cnt.created += 1
        return this._strings.push(_.pick(s, k))
      }
    })
    this._changed()
    return cnt
  }
}

//-------------------------------------------------------------------------------

module.exports = Strings
