






















_ = require 'lodash'

{dataset} = require '../../store'

module.exports =
  components:
    modal: require '../modal'
    checkbox: require '../checkbox'

  data: ->
    unused: []

  mounted: ->
    @unused = _.map dataset.unusedStrings(), (s) ->
      s._selected = no
      s

  computed:
    unused_count: -> _.size _.filter @unused, '_selected'

  methods:
    onClose: (res) ->
      if res is 'ok'
        dataset.strings.delete _.filter @unused, '_selected'
      @$emit 'close', res

    selectAll: -> s._selected = yes for s in @unused

    deselectAll: -> s._selected = no for s in @unused

