











module.exports =
  props:
    label:
      type: String
      default: ''
    value:
      type: Boolean
      required: true
    disabled:
      type: Boolean
      default: false

  methods:
    toggle: -> @$emit 'input', not @value unless @disabled

