const _ = require('lodash')
import { v4 as uuid } from 'uuid'

const utils = require('../../utils')

//-------------------------------------------------------------------------------

const ROOT_NODE = {
  id: 'root',
  label: 'root'
}

//-------------------------------------------------------------------------------

class Graph {
  constructor(_dataset) {
    this._dataset = _dataset
  }

  _reset() {
    this._nodes = []
    return (this._edges = [])
  }

  _serialize() {
    for (const n of this._nodes) {
      if (_.isArray(n.products)) {
        n.products = _.compact(n.products)
        n.products = _.uniq(n.products)
      }
      /*
      if (n.id === 'edcfb224-599c-4090-ac8c-ce669632e5e1') {
        console.log(n);
      }
      */
    }
    return JSON.stringify({
      nodes: this._nodes,
      edges: this._edges
    })
  }

  _deserialize(data) {
    data = JSON.parse(data || '{}')
    this._nodes = _.map(data.nodes, _.cloneDeep)
    if (!this.getRoot()) {
      this._nodes.push(_.cloneDeep(ROOT_NODE))
    }
    this._nodes = _.map(this._nodes, (n) => {
      n.products = n.products || []
      return n
    })
    return (this._edges = _.map(data.edges, _.cloneDeep))
  }

  _changed() {
    this._dataset.emit('graph.changed')
    return this._dataset._changed()
  }

  getNodes() {
    return this._nodes
  }
  getEdges() {
    return this._edges
  }
  getRootIdx() {
    return _.findIndex(this._nodes, ROOT_NODE)
  }
  getRoot() {
    return this._nodes[this.getRootIdx()]
  }

  getNode(data) {
    const id = (data != null ? data.id : undefined) || data
    if (id) {
      return _.find(this._nodes, { id })
    }
  }

  getNeighbours(data) {
    let res = _.filter(
      this._edges,
      (e) => e.from === data.id || e.to === data.id
    )
    res = _.map(res, function (e) {
      if (e.from === data.id) {
        return e.to
      } else {
        return e.from
      }
    })
    return (res = _.map(res, (id) =>
      _.pick(_.find(this._nodes, { id }), ['id', 'label'])
    ))
  }

  addNode(data) {
    data = {
      id: uuid(),
      label: data.label || '',
      x: data.x || 0,
      y: data.y || 0
    }
    this._nodes.push(data)
    this._dataset.emit('graph.node-added', data)
    return this._changed()
  }

  removeNode(data) {
    data = _.castArray(data)
    data = _.map(data, (n) => _.findIndex(this._nodes, { id: n }))
    data = _.without(data, this.getRootIdx())
    data = _.pullAt(this._nodes, data)
    data = _.map(data, 'id')
    this._dataset.emit('graph.node-removed', data)
    return this._changed()
  }

  changeNode(data) {
    const idx = _.findIndex(this._nodes, { id: data.id })
    if (idx < 0) {
      return
    } // FIXME assert
    const n = this._nodes[idx]
    _.merge(n, _.omit(data, ['id']))
    this._dataset.emit('graph.node-changed', n)
    return this._changed()
  }

  addEdge(data) {
    if (
      _.some(
        this._edges,
        (e) =>
          (e.from === data.from && e.to === data.to) ||
          (e.to === data.from && e.from === data.to)
      )
    ) {
      return
    }
    data = {
      id: uuid(),
      from: data.from,
      to: data.to
    }
    this._edges.push(data)
    this._dataset.emit('graph.edge-added', data)
    return this._changed()
  }

  removeEdge(data) {
    data = _.castArray(data)
    data = _.map(data, (n) => _.findIndex(this._edges, { id: n }))
    data = _.pullAt(this._edges, data)
    data = _.map(data, 'id')
    this._dataset.emit('graph.edge-removed', data)
    return this._changed()
  }

  validateNode(n) {
    let v
    const id = _.isString(n) ? n : n != null ? n.id : undefined
    n = _.find(this._nodes, { id })
    if (!n) {
      return
    }
    const res = {
      errors: [],
      warnings: []
    }
    if (!n.label) {
      res.errors.push('label-missing')
    }
    // check caption
    const caption = this._dataset.strings.get(n.caption)
    if (!caption) {
      res.errors.push('caption-missing')
    }
    if (caption) {
      v = this._dataset.strings.validate(caption)
      if (v) {
        res.warnings.push(`caption-translations:${v}`)
      }
    }
    // check content
    const content = this._dataset.strings.get(n.content)
    if (content) {
      v = this._dataset.strings.validate(content)
      if (v) {
        res.warnings.push(`content-translations:${v}`)
      }
    }
    // headline
    if (n.content && !n.headline) {
      res.errors.push('headline-missing')
    }
    const headline = this._dataset.strings.get(n.headline)
    if (headline) {
      v = this._dataset.strings.validate(headline)
      if (v) {
        res.warnings.push(`headline-translations:${v}`)
      }
    }
    return res
  }

  query(q) {
    q = utils.sanitizeSearchString(q)
    q = new RegExp(q, 'gi')
    let res = _.filter(
      this._nodes,
      (n) => (n.label != null ? n.label.search(q) : undefined) >= 0
    )
    return (res = _.map(res, (n) => _.pick(n, ['id', 'label'])))
  }

  stringUsed(id) {
    for (let n of Array.from(this._nodes)) {
      if (n.caption === id || n.content === id || n.headline === id) {
        return true
      }
    }
  }
}

//-------------------------------------------------------------------------------

module.exports = Graph
