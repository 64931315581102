










_ = require 'lodash'
{dataset} = require '../../store'
state = require '../../state'

#-------------------------------------------------------------------------------

module.exports =
  components:
    typeahead: require '../typeahead'

  props:
    name:
      type: String
      default: ''
    value:
      type: String
      default: ''

  data: ->
    currid: ''
    missing: no

  watch:
    value: (v) -> @setid v

  mounted: ->
    @setid @value

  methods:
    query: (txt) -> dataset.strings.query txt
    get: (id) -> dataset.strings.get id

    setid: (id) ->
      @currid = id
      @missing = not dataset.strings.get id

    goto: ->
      state.emit 'goto.string', @currid if @currid

    changed: (id) ->
      @setid id
      @$emit 'change',
        key: @name
        value: @currid

