const EventEmitter = require('eventemitter2').EventEmitter2
const _ = require('lodash')
const api = require('../../services/api')
const Graph = require('./graph')
const Strings = require('./strings')

//-------------------------------------------------------------------------------

class Dataset extends EventEmitter {
  constructor() {
    super()
    this.strings = new Strings(this)
    this.graph = new Graph(this)
    this.reset()
  }

  _changed(pristine) {
    this._pristine = pristine || false
    return this.emit('changed')
  }

  isPristine() {
    return this._pristine
  }
  getName() {
    return this._meta != null ? this._meta.name : undefined
  }

  reset() {
    this._meta = null
    this.graph._reset()
    this.strings._reset()
    this._pristine = true
    return this.emit('loaded')
  }

  load(id) {
    return api.get({ path: `/dataset/${id}`, block: true }, (err, res) => {
      if (err) {
        console.error(err)
      } // TODO modal
      if (err) {
        return this.reset()
      }
      this._meta = _.pick(res, ['_id', 'name'])
      this.strings._deserialize(res.strings)
      this.graph._deserialize(res.graph)
      return this.emit('loaded')
    })
  }

  save(opt, cb) {
    if (_.isFunction(opt)) {
      cb = opt
      opt = null
    }
    const doc = _.assign({}, this._meta, {
      strings: this.strings._serialize(),
      graph: this.graph._serialize()
    })
    const req = {
      path: `/dataset/${doc._id}/save`,
      data: doc,
      block: (opt != null ? opt.background : undefined) !== true
    }
    return api.post(req, (err) => {
      if (err) {
        console.error(err)
      }
      if (err) {
        return typeof cb === 'function' ? cb(err) : undefined
      }
      this._changed(true)
      return typeof cb === 'function' ? cb() : undefined
    })
  }

  unusedStrings() {
    const p = ['id', 'label']
    let res = _.map(this.strings._strings, (s) => {
      if (!this.graph.stringUsed(s.id)) {
        return _.pick(s, p)
      }
    })
    return (res = _.compact(res))
  }
}

//-------------------------------------------------------------------------------

module.exports = new Dataset()
