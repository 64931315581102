












{dataset} = require '../../store'
state = require '../../state'

module.exports =
  components:
    typeahead: require '../typeahead'

  data: ->
    currid: ''
    focused: no

  methods:
    query: (txt) -> dataset.graph.query txt
    get: (id) -> dataset.graph.getNode id

    goto: ->
      state.emit 'goto.string', @currid if @currid

    changed: (id) ->
      @currid = id
      state.selectNode @currid if @currid
      @$nextTick ->
        @currid = ''
        window.document.activeElement?.blur()

    choice: (id) ->
      state.selectNode id if id

