






















module.exports =
  props:
    hasCancel:
      type: Boolean
      default: no
    hasButtons:
      type: Boolean
      default: yes
    transition:
      type: String
      default: 'fade'

  methods:
    close: (res) -> @$emit 'close', res

  computed:
    enterClass: -> "#{@transition}In"
    leaveClass: -> "#{@transition}Out"

