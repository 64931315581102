<template>
  <div class="home">
    <div v-if="has_user" class="welcome window window-shadow animated flipInX">
      <span> {{ $t('home.welcome') }}&nbsp; </span>
      <em class="fg-primary">
        {{ user.email }}
      </em>
    </div>
  </div>
</template>

<!--=========================================================================-->

<script>
  module.exports = {
    mixins: [require('../mixins/user')]
  }
</script>

<!--=========================================================================-->

<style lang="sass" scoped>

  @import '../theme'

  .home
    display: flex
    align-items: center
    justify-content: center

  .welcome
    +no-text-select
    flex: 1
    text-align: center
    padding: 1em
    font-weight: bold
    font-size: 1.2em
    border-radius: 0
</style>
