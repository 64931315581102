















_ = require 'lodash'

module.exports =
  props:
    defaultSplit:
      default: 70
    minSplit:
      default: 20
    maxSplit:
      default: 80
  data: ->
    split: _.clamp @defaultSplit, @minSplit, @maxSplit
    dragging: false
  methods:
    dragStart: (e) ->
      @dragging = true
      @startX = e.pageX
    dragMove: (e) ->
      if @dragging
        dx = e.pageX - @startX
        @startX = e.pageX
        dragger = @$el.querySelector '.dragger'
        w = @$el.offsetWidth - dragger.offsetWidth
        @split = @split + (dx / w) * 100
        @split = _.clamp @split, @minSplit, @maxSplit
    dragEnd: ->
      @dragging = false

