<template>
  <div class="window inspector">
    <div v-if="!target" key="empty" class="empty animated fadeIn">
      <div class="text">{{ $t('inspector.empty_content') }}</div>
    </div>
    <div v-else key="content" class="content animated fadeIn">
      <ul v-if="readonly">
        <li class="key-block">
          <div class="key-label">
            {{ $t('inspector.keylabel.label') }}:<span
              style="font-weight: normal;"
              >&nbsp;{{ target.label }}</span
            >
          </div>
        </li>
      </ul>
      <ul v-if="!readonly">
        <li v-for="k in visiblekeys" class="key-block">
          <div class="key-label">
            <span v-if="keymap[k].lang"
              ><i class="flag-icon" :class="['flag-icon-' + keymap[k].lang]"></i
              >&nbsp;</span
            >{{ $t('inspector.keylabel.' + k) }}:
          </div>
          <key-text
            v-if="keymap[k].type === 'textarea'"
            :name="k"
            :value="target[k]"
            :multiline="true"
            @change="keyChange"
          ></key-text>
          <key-text
            v-if="keymap[k].type === 'text'"
            :name="k"
            :value="target[k]"
            @change="keyChange"
          ></key-text>
          <key-string
            v-if="keymap[k].type === 'string'"
            :name="k"
            :value="target[k]"
            @change="keyChange"
          ></key-string>
          <key-number
            v-if="keymap[k].type === 'number'"
            :name="k"
            :value="target[k]"
            :min="keymap[k].min"
            :max="keymap[k].max"
            @change="keyChange"
          ></key-number>
          <key-product-set
            v-if="keymap[k].type === 'product-set'"
            :name="k"
            :value="target[k]"
            @change="keyChange"
          ></key-product-set>
        </li>
        <li v-if="mode === 'nodes'" class="key-block">
          <div
            class="key-label"
            :class="{ 'fg-danger': validation.errors.length }"
          >
            {{
              $t('inspector.speciallabel.node_errors', {
                count: validation.errors.length
              })
            }}
          </div>
          <ul class="errors">
            <li v-for="e in validation.errors">- {{ renderError(e) }}</li>
          </ul>
        </li>
        <li v-if="mode === 'nodes'" class="key-block">
          <div
            class="key-label"
            :class="{ 'fg-primary': validation.warnings.length }"
          >
            {{
              $t('inspector.speciallabel.node_warnings', {
                count: validation.warnings.length
              })
            }}
          </div>
          <ul class="warnings">
            <li v-for="w in validation.warnings">- {{ renderError(w) }}</li>
          </ul>
        </li>
      </ul>
      <ul v-if="mode === 'nodes'">
        <li class="key-block">
          <div class="key-label">
            {{
              $t('inspector.speciallabel.node_connections', {
                count: connections.length
              })
            }}
          </div>
          <ul class="connections">
            <li v-for="c in connections" @click="selectConnection(c)">
              {{ c.label || c.id }}
            </li>
          </ul>
        </li>
      </ul>
      <ul v-if="showhidden" class="hidden-keys">
        <li v-for="k in keys">
          <strong>{{ k }}:&nbsp;</strong><span>{{ target[k] || '-' }}</span>
        </li>
      </ul>
    </div>
    <div v-show="target && locked" class="locked-overlay">
      <div class="icon"><i class="fa fa-lock"></i></div>
    </div>
  </div>
</template>

<!--=========================================================================-->
<script lang="js">
  const _ = require('lodash');
  const api = require('../../services/api');

  const {dataset} = require('../../store');
  const state = require('../../state');
  const conf = require('../../conf');

  const PRODUCTION = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'testing'

  //-------------------------------------------------------------------------------

  const NODE_KEYS = {
    label: {
      type: 'text',
      sort: 0
    },
    anim_id: {
      type: 'text'
    },
    menu_id: {
      type: 'text'
    },
    caption: {
      type: 'string',
      sort: 1
    },
    content: {
      type: 'string',
      sort: 2
    },
    headline: {
      type: 'string',
      sort: 3
    },
    products: {
      type: 'product-set',
      sort: 4
    },
    sortvalue: {
      type: 'number',
      min: -100,
      max: 100,
      sort: 5
    }
  };

  //-------------------------------------------------------------------------------

  const STRING_KEYS = {
    label: {
      type: 'text',
      sort: 0
    }
  };

  _.each(conf.dataset.strings.locales, (l, li) => STRING_KEYS[`text_${l}`] = {
    type: 'textarea',
    lang: l,
    sort: STRING_KEYS.label.sort + li + 1
  });

  //-------------------------------------------------------------------------------

  module.exports = {
    mixins: [
      require('../../mixins/user')
    ],

    components: {
      keyText: require('./text'),
      keyString: require('./string'),
      keyNumber: require('./number'),
      keyProductSet: require('./product-set')
    },

    props: [ 'mode' ],

    data() {
      return {
        target: null,
        showhidden: !PRODUCTION,
        keys: [],
        keymap: {},
        connections: [],
        validation: {}
      };
    },

    mounted() {
    switch (this.mode) {
      case 'nodes': {
        state.on('node.selected', this._nodeSelected);
        return this._nodeSelected(state.getSelectedNode());
      }
      case 'strings': {
        state.on('string.selected', this._stringSelected);
        return this._stringSelected(state.getSelectedString());
      }
    }
    },

    beforeDestroy() {
      switch (this.mode) {
        case 'nodes':
          return state.off('node.selected', this._nodeSelected);
        case 'strings':
          return state.off('string.selected', this._stringSelected);
      }
    },

    computed: {
      visiblekeys() { return _.filter(this.keys, k => _.has(this.keymap, k)); },
      readonly() { return (this.mode === 'nodes') && ((this.target != null ? this.target.id : undefined) === 'root'); },
      locked() {
        const can = (() => { switch (this.mode) {
          case 'nodes': return this.user.cms_rights.modify_nodes;
          case 'strings': return this.user.cms_rights.modify_strings;
        } })();
        return !can;
      }
    },

    methods: {
      renderError(e) {
        e = _.split(e, ':');
        e[0] = `inspector.errors.${e[0]}`;
        e[1] = e[1] || [];
        return this.$t(e[0], {arg0: e[1]});
      },

      keyChange(e) {
        if (!this.target) { return; }
        this.$set(this.target, e.key, e.value);
        switch (this.mode) {
          case 'nodes': return dataset.graph.changeNode(this.target);
          case 'strings': return dataset.strings.change(this.target);
        }
      },

      _setupTarget(o, km) {
        this.target = o ? (_.cloneDeep(o)) : null;
        this.keymap = km;
        this.keys = _.uniq(_.concat((_.keys(this.target)), (_.keys(this.keymap))));
        return this.keys.sort((a, b) => {
          a = _.get(this.keymap[a], 'sort', 1000);
          b = _.get(this.keymap[b], 'sort', 1000);
          if (a < b) { return -1; }
          if (a > b) { return 1; }
          return 0;
        });
      },

      _nodeSelected(n) {
        this._setupTarget(n, NODE_KEYS);
        this.connections = this.target ? (dataset.graph.getNeighbours(this.target)) : [];
        this.validation = this.target ? (dataset.graph.validateNode(this.target)) : {};
        return this.$emit('scroll-top');
      },

      _stringSelected(s) {
        this._setupTarget(s, STRING_KEYS);
        this.connections = [];
        this.validation = {};
        return this.$emit('scroll-top');
      },

      selectConnection(n) { return state.selectNode(n); }
    }
  };
</script>

<!--=========================================================================-->

<style lang="sass" scoped>

  @import '../../theme'

  .inspector
    flex: 1 1
    position: relative

    .empty
      +no-text-select
      height: 100%

      .text
        font-size: 1.5em
        font-weight: lighter
        text-align: center
        padding-top: 50%
        color: rgba($text-fg, 0.4)

    .content
      padding: 0.8em

    select
      width: 100%

  .locked-overlay
    +no-text-select
    display: flex
    align-items: center
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(255, 255, 255, 0.5)

    .icon
      flex: 1 1
      font-size: 20em
      color: black
      text-align: center
      opacity: 0.15

  .key-block
    background: rgba(white, 0.25)
    padding: 0.25rem
    margin-bottom: 0.25rem

  .key-label
    +no-text-select
    font-weight: bold
    font-size: 0.8em
    line-height: 160%

  .hidden-keys
    border-top: 1px solid $border-bg
    margin-top: 1rem
    padding-top: 0.5rem
    color: lighten($text-fg, 40%)
    font-size: 0.8em

    li
      line-height: 160%

  .errors, .warnings
    font-size: 0.8em
    line-height: 160%

  .connections
    +no-text-select
    font-size: 0.8em
    line-height: 160%

    li
      cursor: pointer
      transition: color $anim-fast ease

      &:before
        display: inline-block
        content: '-'
        margin-right: 0.25em

      &:hover
        color: $text-fg-primary
</style>
