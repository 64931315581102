









_ = require 'lodash'
api = require '../services/api'

module.exports =
  #components:
    #topProgress: require 'vue-top-progress'

  mounted: ->
    api.on 'request.start', @_requestStart
    api.on 'request.end', @_requestEnd
    api.on 'request.error', @_requestEnd

  beforeDestroy: ->
    api.off 'request.error', @_requestEnd
    api.off 'request.end', @_requestEnd
    api.off 'request.start', @_requestStart

  data: ->
    all: 0
    blocking: 0
    bar_height: 1
    bar_color: '#3773DB'

  methods:
    _requestStart: (req) ->
      @_changeRequests 1, (1 if req.block)

    _requestEnd: (req) ->
      _.defer => @_changeRequests -1, (-1 if req.block)

    _changeRequests: (a, b) ->
      prev_all = @all
      @all += a or 0
      @blocking += b or 0
      #@$refs.progress.done() if prev_all > 0 and @all <= 0
      #@$refs.progress.start() if prev_all <= 0 and @all > 0
      @bar_height = 2 # if @blocking > 0 then 3 else 2

