const _ = require('lodash')
const api = require('../services/api')

//-------------------------------------------------------------------------------

const BLANK_USER = { cms_rights: {} }

//-------------------------------------------------------------------------------

module.exports = {
  data() {
    return {
      user: _.cloneDeep(BLANK_USER),
      has_user: false
    }
  },

  mounted() {
    api.on('authorization.change', this._refresh_user)
    return this._refresh_user()
  },

  beforeDestroy() {
    return api.off('authorization.change', this._refresh_user)
  },

  methods: {
    _refresh_user() {
      const usr = api.getUser()
      this.has_user = usr != null
      return (this.user = _.assign({}, usr || _.cloneDeep(BLANK_USER)))
    }
  }
}
