



































{dataset} = require '../store'
state = require '../state'

module.exports =
  mixins: [
    require '../mixins/user'
  ]

  components:
    checkbox: require '../components/checkbox'
    splitPane: require '../components/split-pane'
    graphCanvas: require '../components/graph'
    inspector: require '../components/inspector'
    graphTester: require '../components/tester'
    stringManager: require '../components/strings'

  data: ->
    tab: 'graph'
    pristine: yes
    name: ''
    is_saving: no
    autosave_enabled: no
    autosave_interval: null

  mounted: ->
    dataset.on 'changed', @_datasetChanged
    dataset.on 'loaded', @_datasetChanged
    state.on 'goto.string', @_gotoString
    state.on 'goto.node', @_gotoNode
    dataset.load @$route.params.id
    @autosave_interval = setInterval @_autosave, 60 * 1000

  beforeDestroy: ->
    clearInterval @autosave_interval
    state.off 'goto.node', @_gotoNode
    state.off 'goto.string', @_gotoString
    dataset.off 'loaded', @_datasetChanged
    dataset.off 'changed', @_datasetChanged

  destroyed: ->
    dataset.reset()

  methods:
    onSave: ->
      return if @is_saving
      @is_saving = yes
      dataset.save => @is_saving = no

    onScrollTop: ->
      (@$el.querySelector '.pane .right')?.scrollTop = 0

    _autosave: ->
      return unless @autosave_enabled
      return if dataset.isPristine() or @is_saving
      @is_saving = yes
      dataset.save background: yes, => @is_saving = no

    _datasetChanged: ->
      @pristine = dataset.isPristine()
      @name = dataset.getName()

    _gotoString: (id) ->
      @tab = 'strings'
      @$nextTick ->
        state.selectString id

    _gotoNode: (id) ->
      @tab = 'graph'
      @$nextTick ->
        state.selectNode id

