

















_ = require 'lodash'
Vue = require 'vue'

{dataset} = require '../../store'
state = require '../../state'

module.exports =
  name: 'testerMenu'
  props:
    baseId:
      type: String
      default: ''
    node:
      type: Object
    current:
      type: Object
    level:
      type: Number
      default: 0
    locale:
      type: String
    maxLevel:
      type: Number
      default: 1000
  data: ->
    toggled: ''
  mounted: ->
    @_deserialize()
  watch:
    node: -> @_deserialize()
    toggled: (v) -> _.set state, "tester.menu.#{@fullId}", v
  computed:
    buttons: -> _.sortBy @node?._children, (c) -> c.sortvalue or 0
    fullId: -> "#{@baseId}_#{@node?.id}"
  methods:
    _deserialize: ->
      @toggled = _.get state, "tester.menu.#{@fullId}", ''
    error: (n) ->
      cap = @caption n
      (_.startsWith cap, 'label:') or (_.startsWith cap, 'locale:') or @level > @maxLevel
    caption: (n) ->
      n and dataset.strings.translate n.caption, @locale, "label:#{n.label}"
    highlight: (s) ->
      s = @filter$highlight s, 'label:'
      s = @filter$highlight s, 'locale:'
    toggle: (b) ->
      unless b.menu_id
        @toggled = if @toggled is b.id then '' else b.id
      @$emit 'open', b
    onOpen: (b) ->
      @$emit 'open', b
    btnIcon: (b) ->
      toggled = b.id is @toggled
      empty = _.isEmpty b._children
      hasmenu = b.menu_id?
      return 'fa-share-square-o' if hasmenu
      if toggled
        return if empty then 'fa-square-o' else 'fa-minus-square-o'
      else
        return if empty then 'fa-square-o' else 'fa-plus-square-o'

