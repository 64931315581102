<template>
  <div class="key-product-set">
    <checkbox
      v-for="p in products"
      :key="p._id"
      :value="hasProduct(p.tag)"
      :label="p.name"
      @input="toggleProduct(p.tag)"
    ></checkbox>
  </div>
</template>

<!--=========================================================================-->

<script>
  const _ = require('lodash')
  const api = require('../../services/api')

  module.exports = {
    components: {
      checkbox: require('../checkbox')
    },
    props: {
      name: {
        type: String,
        required: true
      },
      value: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data() {
      return {
        products: []
      }
    },
    created() {
      api.get('/products/enum', (err, res) => {
        this.products = res
      })
    },
    methods: {
      hasProduct(tag) {
        return _.includes(this.value, tag)
      },
      toggleProduct(tag) {
        let value = this.hasProduct(tag)
          ? _.without(this.value, tag)
          : _.concat([], this.value, tag)
        value = _.compact(value)
        value = _.uniq(value)
        this.$emit('change', {
          key: this.name,
          value
        })
      }
    }
  }
</script>

<!--=========================================================================-->

<style lang="sass" scoped>
  @import '../../theme'
</style>
