













_ = require 'lodash'
vis = require 'vis'

state = require '../../state'
{dataset} = require '../../store'

#-------------------------------------------------------------------------------

COLOR =
  root_background: 'rgb(73, 73, 73)'
  node_background: 'rgb(255, 255, 255)'
  node_background_warn: 'rgb(150, 200, 250)'
  node_background_err: 'rgb(247, 168, 173)'
  highlight: 'rgb(55, 115, 219)'

GRAPH_STYLE =
  width: '320px'
  height: '240px'
  autoResize: true
  layout:
    randomSeed: 2
  physics:
    enabled: false
  edges:
    arrows:
      to: true
    smooth:
      type: 'cubicBezier'
  nodes:
    color:
      background: COLOR.node_background
      border: COLOR.node_background
      highlight:
        background: COLOR.node_background
        border: COLOR.highlight
    font:
      face: 'Lato,sans-serif'
      size: 14
      color: 'rgb(73, 73, 73)'
    labelHighlightBold: false
    shapeProperties:
      borderRadius: 0
  locale: 'en'

#-------------------------------------------------------------------------------

ROOT_STYLE =
  shape: 'diamond'
  size: 16
  color:
    background: COLOR.root_background
    highlight:
      background: COLOR.root_background

NODE_STYLE =
  shape: 'box'

#-------------------------------------------------------------------------------

module.exports =
  mixins: [
    require '../../mixins/user'
  ]

  components:
    searchBox: require './search-box'

  mounted: ->
    canedit = @user.cms_rights.create_and_delete_nodes

    options = _.assign {}, GRAPH_STYLE,
      interaction:
        multiselect: true
        dragNodes: @user.cms_rights.modify_nodes
      manipulation:
        enabled: canedit
        initiallyActive: canedit
        addNode: (data, cb) =>
          cb() # FIXME add and remove instead of disable/enable?
          @instance.disableEditMode()
          @instance.enableEditMode()
          dataset.graph.addNode
            label: '#new node'
            x: data.x
            y: data.y
        addEdge: (data, cb) ->
          dataset.graph.addEdge data if data.from isnt data.to
          cb()
        editEdge: false
        deleteNode: (data, cb) ->
          unless _.includes data.nodes, dataset.graph.getRoot()?.id
            dataset.graph.removeNode data.nodes
            dataset.graph.removeEdge data.edges
          cb()
        deleteEdge: (data, cb) ->
          unless _.includes data.nodes, dataset.graph.getRoot()?.id
            dataset.graph.removeNode data.nodes
            dataset.graph.removeEdge data.edges
          cb()

    _.set options, 'locales.en',
      edit: '#edit'
      del: @$t 'graph.vis.remove'
      back: @$t 'graph.vis.back'
      addNode: @$t 'graph.vis.add_node'
      addEdge: @$t 'graph.vis.add_edge'
      editNode: '#editNode'
      editEdge: '#editEdge'
      addDescription: @$t 'graph.vis.hint_add_node'
      edgeDescription: @$t 'graph.vis.hint_add_edge'
      editEdgeDescription: '#editEdgeDescription'
      createEdgeError: '#createEdgeError'
      deleteClusterError: '#deleteClusterError'
      editClusterError: '#editClusterError'

    @network =
      nodes: new vis.DataSet()
      edges: new vis.DataSet()
    @instance = new vis.Network (@$el.querySelector '.canvas'), @network, options
    @instance.on 'click', (e) ->
      window.document.activeElement?.blur()
      state.selectNode (_.first e.nodes), nofocus: true
    @instance.on 'dragEnd', (e) =>
      window.document.activeElement?.blur()
      @_saveViewport()
      state.selectNode (_.first e.nodes), nofocus: true unless _.isEmpty e.nodes
      pos = @instance.getPositions e.nodes
      for n in e.nodes
        dataset.graph.changeNode
          id: n
          x: pos[n]?.x or 0
          y: pos[n]?.y or 0
    @instance.on 'zoom', (z) =>
      @_saveViewport()

    dataset.on 'loaded', @_loaded
    dataset.on 'graph.node-added', @_nodeAdded
    dataset.on 'graph.node-removed', @_nodeRemoved
    dataset.on 'graph.node-changed', @_nodeChanged
    dataset.on 'graph.edge-added', @_edgeAdded
    dataset.on 'graph.edge-removed', @_edgeRemoved
    state.on 'node.selected', @_nodeSelected

    window.document.addEventListener 'keyup', @onKey

    @_resize =
      width: 0
      height: 0
      first: true
      interval: null
      check: =>
        w = @$el.clientWidth
        h = @$el.clientHeight
        if @_resize.first or w isnt @_resize.width or h isnt @_resize.height
          @instance.setSize "#{w}px", "#{h}px"
          @instance.redraw()
          @_resize.width = w
          @_resize.height = h
          @_resize.first = false

    @$nextTick ->
      @_resize.check()
      @_resize.interval = setInterval @_resize.check, 250
      @rebuild()

  beforeDestroy: ->
    window.document.removeEventListener 'keyup', @onKey
    clearInterval @_resize.interval
    state.off 'node.selected', @_nodeSelected
    dataset.off 'graph.edge-added', @_edgeAdded
    dataset.off 'graph.edge-removed', @_edgeRemoved
    dataset.off 'graph.node-added', @_nodeAdded
    dataset.off 'graph.node-removed', @_nodeRemoved
    dataset.off 'graph.node-changed', @_nodeChanged
    dataset.off 'loaded', @_loaded
    @instance?.destroy()
    @instance = null

  data: ->
    switches:
      errors: false
      warnings: false

  methods:
    rebuild: ->
      @network.nodes.clear()
      @network.nodes.add _.map dataset.graph.getNodes(), @_compileNode
      @network.edges.clear()
      @network.edges.add _.map dataset.graph.getEdges(), @_compileEdge
      ns = state.getSelectedNode()
      if ns
        @instance.selectNodes [ ns.id ]

      unless state.graph
        @instance.fit @instance.getSelectedNodes()
        @_saveViewport()
      else
        @instance.moveTo
          position: state.graph.position
          scale: state.graph.scale
        @_saveViewport()

    _saveViewport: ->
      _.set state, 'graph.position', @instance.getViewPosition()
      _.set state, 'graph.scale', @instance.getScale()

    _compileNode: (n) ->
      isroot = n.id is 'root'
      res = _.assign {}, (if isroot then ROOT_STYLE else NODE_STYLE),
        id: n.id
        label: if isroot then '' else "  #{n.label}  "
        fixed: isroot
        x: if isroot then 0 else n.x
        y: if isroot then 0 else n.y
      unless isroot
        v = dataset.graph.validateNode n
        has_errors = not _.isEmpty v?.errors
        has_warnings = not _.isEmpty v?.warnings
        if @switches.warnings and has_warnings
          _.set res, 'color.background', COLOR.node_background_warn
          _.set res, 'color.border', COLOR.node_background_warn
        if @switches.errors and has_errors
          _.set res, 'color.background', COLOR.node_background_err
          _.set res, 'color.border', COLOR.node_background_err
      res

    _compileEdge: (e) ->
      e = _.pick e, [ 'id', 'from', 'to' ]

    _loaded: -> @rebuild()

    _nodeAdded: (n) -> @network.nodes.add @_compileNode n
    _nodeRemoved: (n) -> @network.nodes.remove n
    _nodeChanged: (n) -> @network.nodes.update @_compileNode n
    _nodeSelected: (n, opt) ->
      return unless n
      @$nextTick ->
        @instance.selectNodes [ n.id ]
        @instance.focus n.id unless opt.nofocus

    _edgeAdded: (e) -> @network.edges.add @_compileEdge e
    _edgeRemoved: (e) -> @network.edges.remove e

    onSwitch: (s) ->
      @switches[s] = not @switches[s]
      @rebuild()

    onKey: (e) ->
      doc = window.document
      return unless doc.activeElement is doc.body
      switch e.key
        when 'a'
          return unless @user.cms_rights.create_and_delete_nodes
          @instance.addNodeMode()
          e.preventDefault()
        when 'c'
          return unless @user.cms_rights.create_and_delete_nodes
          @instance.addEdgeMode()
          e.preventDefault()
        when 'Backspace'
          return unless @user.cms_rights.create_and_delete_nodes
          @instance.deleteSelected()
          e.preventDefault()

